<template>
  <div class="module_one_block">
    <div class="module_one_header_block">
      <div class="module_one_header_container">
        <div class="module_one_header_label">
          {{ course.title }}
        </div>
      </div>
    </div>
    <div class="module_one_main_block">
      <div class="module_one_main_container">
        <div class="module_one_content_container">
          <div class="module_one_plot m-0" v-if="!course">
            <el-skeleton :rows="6"/>
          </div>
          <div class="module_one_plot m-0" v-else>
            <router-link :to="{ name: 'about-course', slug: course.slug }" class="module_one_plot_module">
              {{ $t('About_course') }}
            </router-link>
            <router-link
              v-for="module in course.modules"
              :to="{ name: 'course-module', params: { slug: course.slug, module: module.id } }"
              :class="{active: $route.params.module == module.id}"
              class="module_one_plot_module">
              {{ module.title }}
            </router-link>
            <a class="module_one_plot_about">
              {{ $t('Final_testing') }}
            </a>
          </div>
        </div>
        <div class="module_one_desc" :class="{ disabled: !course.quiz.available }">
          <div>
            <div class="final_testing_desc-new" v-if="course.quiz">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="12" viewBox="0 0 16 12" fill="none">
                <path d="M14.875 1.625L6.125 10.3746L1.75 6" stroke="#305AA6" stroke-width="2" stroke-linecap="round"
                      stroke-linejoin="round"/>
              </svg>
              <div style="width: 100%">
                <div class="module_two_desc_test_label-title">
                  {{ course.quiz.title }}
                </div>
                <div class="module_two_desc_test_btn-new">
                  <div class="courses_list_bad_item_date">

                    <router-link v-if="course.quiz.available"
                                 :class="{'active-link-quiz':course.quiz.percent}"
                                 :to="{ name: 'course-final-exercise', params: { slug: course.slug, quiz: course.quiz.id } }"
                                 class="pointer module_two_desc_test_btn_start">
                      <span v-if="course.quiz.percent">Пройдено</span>
                      <span v-else>Начать</span>

                    </router-link>
                    <button class="module_two_desc_test_btn_start disabled" v-else>Тест недоступен</button>

                    <a  v-if="course.certificate" download target="_blank" class="download-cert" :href="'https://api.birgeoqy.kz/storage/'+course.certificate">
                      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                        <path d="M6.71875 8.59375L10 11.875L13.2812 8.59375" stroke="#305AA6" stroke-width="2"
                              stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M10 3.12305V11.873" stroke="#305AA6" stroke-width="2" stroke-linecap="round"
                              stroke-linejoin="round"/>
                        <path
                          d="M17.5 10.625V16.25C17.5 16.4158 17.4342 16.5747 17.3169 16.6919C17.1997 16.8092 17.0408 16.875 16.875 16.875H3.125C2.95924 16.875 2.80027 16.8092 2.68306 16.6919C2.56585 16.5747 2.5 16.4158 2.5 16.25V10.625"
                          stroke="#305AA6" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                      </svg>
                      <span>Скачать сертификат</span></a>
                    <h1 class="rate-final" v-if="course.quiz.percent">{{course.quiz.percent}}%</h1>
                  </div>
                </div>
              </div>
            </div>
            <div class="final_testing_desc_text_img" v-if="course.quiz && course.quiz.quiz_rules">
              <div class="final_testing_desc_text pt-4" v-html="course.quiz.quiz_rules"></div>
              <div class="final_testing_desc_img"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FinalTesting',
  data() {
    return {
      course: false,
    }
  },
  methods: {
    getCourseDetails() {
      let apiRoot = API_ROOT
      let slug = this.$route.params.slug
      this.$http.get(`${apiRoot}/api/course/${slug}`)
        .then(res => {
          this.course = res.body
        })
        .catch(err => console.log(err))
        .finally(() => {

        })
    },
  },
  mounted() {
    this.getCourseDetails()
  }
}
</script>

<style lang="scss" scoped>

.courses_list_bad_item_date{
  width: 100%;
}
.final_testing_desc-new {
  position: relative;
  display: flex;
  background: #F1F6FF;
  padding: 25px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}

.module_two_desc_test_btn-new {
  margin-top: 12px;
  margin-left: 28px;
}

.active-link-quiz {
  background: #305AA6;
}

.module_two_desc_test_label-title {

  margin-left: 28px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #305AA6;
}

.download-cert {
  font-weight: 600;
  text-decoration: none;
  padding: 0 43px;
  display: flex;
  justify-content: center;
  background: #D7E5FF;
  border-radius: 8px;
  height: 36px;
  color: #305AA6;
  font-size: 16px;

  &:hover {
    color: #305AA6;
  }

  svg {
    margin: auto 0;
  }

  span {
    display: block;
    margin: auto 0 auto 22px;
  }
}
.rate-final{
  display: block;
  margin-left: auto;
  color: #C460C0;
font-size: 32px;
}
</style>
